import React from "react";

const InputComponent = ({ onClickFn, value, type }) => {
  return (
    <div className="textAreaComponent">
      <textarea
        style={{
          width: "100%",
          wordWrap: "break-word",
          whiteSpace: "normal",
          border: "1px solid #ccc",
          lineHeight: 2,
          padding: "5px",
          boxSizing: "border-box",
        }}
        type="input"
        onChange={(e) => onClickFn(e.target.value, [type])}
        value={value}
      />
    </div>
  );
};

export default InputComponent;
