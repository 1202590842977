import React from "react";
import "./footer.scss";
import { useAVToggle } from "@100mslive/react-sdk";
import { Icon } from "@iconify/react";
import classNames from "classnames";
import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { callingPageDataActions } from "../../../app/features/callinPageDataSlice";
import { useDispatch } from "react-redux";
const Footer = ({ disabled }) => {
  const dispatch = useDispatch();
  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } =
    useAVToggle();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const hmsActions = useHMSActions();

  const endCallClick = () => {
    if (isConnected) {
      hmsActions.leave();
    }
  };

  const carePlanClick = () => {
    if (disabled) return;
    dispatch(callingPageDataActions.openModal());
  };
  const notesClick = () => {
    dispatch(callingPageDataActions.openModalnotes());
  };
  return (
    <div className="callFooter">
      <div className="callFooterContainer">
        {/* <div className="timeDisplay">
          <div className="element ">
            <p className="subelement">Call Duration</p>
            00:00:00
          </div>
        </div>
        <div className="callbtnsWrap">
          <button className="callBtns pause">
            <Icon icon="ic:baseline-pause" />
          </button>
          <button
            className={classNames("callBtns", "mic", {
              disabled: !isLocalAudioEnabled,
            })}
            onClick={toggleAudio}
          >
            <Icon icon="material-symbols:mic" />
          </button>
          <button
            className={classNames("callBtns", "video", {
              disabled: !isLocalVideoEnabled,
            })}
            onClick={toggleVideo}
          >
            <Icon icon="mdi:video" />
          </button>

          <button
            className={classNames("callBtns", "endCall", {
              disabled: !isConnected,
            })}
            onClick={endCallClick}
          >
            <Icon icon="fluent:call-12-filled" />
          </button>
        </div> */}
        <div className="opencompount">
          <button className="opencompountBtn" onClick={notesClick}>
            Notes
          </button>
          <button className="opencompountBtn" onClick={carePlanClick}>
            Care Plan
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
