import "./notes.scss";

import React, { useEffect, useState } from "react";
import NotesSubComponent from "./NotesSubComponent";
import CareplanSidebar from "../CareplanSidebar";
import { useGetNotesMutation } from "../../../app/api/scheduleCallApi";

const Notes = (props) => {
  const { id, programName, disabled } = props;
  const [getNotesfn, { data }] = useGetNotesMutation();

  const [questionStruct, setquestionStruct] = useState([]);
  const [window, setWindow] = useState("");
  const [notes, setNotes] = useState();
  const onClickSideBar = (value) => {
    setWindow(value);
  };

  useEffect(() => {
    if (!programName || !id) return;
    getNotesfn({
      patientId: id,
      program: programName,
    });
  }, [programName, id]);

  useEffect(() => {
    if (!data) return;
    setquestionStruct(data);
    setNotes(data);
  }, [data]);
  return (
    <div className="notes">
      {questionStruct && (
        <div className="notesBodySection">
          <CareplanSidebar
            window={window}
            sidebarArray={questionStruct?.map((section) => section?.Group)}
            clickfn={onClickSideBar}
          />
          <div className="notes-content-section">
            {questionStruct?.map(
              (section) =>
                window === section?.Group && (
                  <React.Fragment key={section?.Group}>
                    <NotesSubComponent
                      id={id}
                      title={section?.Group}
                      setNotes={setNotes}
                      questionStruct={questionStruct}
                      fieldHeads={section?.subGroupList?.map(
                        (subGroup) => subGroup?.subGroupName
                      )}
                      notes={notes}
                      disabled={disabled}
                    />
                  </React.Fragment>
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Notes;
