import React, { useState, useId, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import "./notesSubComponent.scss";
import { Icon } from "@iconify/react/dist/iconify.js";
import Questionarie from "./Questionarie";
import { useUpdateNotesMutation } from "../../../app/api/scheduleCallApi";
import FoldableComponent from "./FoldableComponent";
const NotesSubComponent = ({
  title,
  id,
  setNotes,
  questionStruct,
  fieldHeads,
  notes,
  disabled,
}) => {
  const [updateNotesFn, { data, isSuccess, error }] = useUpdateNotesMutation();

  const onClickSubMit = () => {
    updateNotesFn({ patientId: id, Questionaire: notes });
  };
  useEffect(() => {
    if (error) {
      FloatingNotifications("error", "'Failed to update note");
    }
    if (isSuccess) {
      FloatingNotifications("success", "Notes have been saved successfully");
    }
  }, [error, isSuccess, updateNotesFn]);
  return (
    <div className="notesSub">
      <div className="headerSection">
        <div className="title">{title}</div>{" "}
        <div className="headerButtonSection">
          {!disabled && (
            <IconButton
              style={{
                borderRadius: "4px",
                padding: "1rem",
                fontSize: "calc(6.32px + 0.632 * 1vw)",
                color: "#fff",
                fontWeight: "600",
                backgroundColor: "#7A5DDA",
              }}
              className="addPatients"
              onClick={() => onClickSubMit()}
            >
              Save
            </IconButton>
          )}
        </div>
      </div>
      <div className="bodySection">
        <>
          {questionStruct
            ?.filter((section) => section.Group === title)[0]
            ?.subGroupList?.map((subGroup) => (
              <FoldableComponent title={subGroup?.subGroupName} open={true}>
                {subGroup?.quesList?.map((question) => (
                  <Questionarie
                    question={question}
                    title={title}
                    fieldHead={subGroup?.subGroupName}
                    setNotes={setNotes}
                    notes={notes}
                  />
                ))}
              </FoldableComponent>
            ))}
        </>
      </div>
    </div>
  );
};

export default NotesSubComponent;
