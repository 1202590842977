import {
  StyleSheet,
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxFIzIFKw.woff2",
      fontWeight: "100",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2",
      fontWeight: "300",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKOzY.woff2",
      fontWeight: "400",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2",
      fontWeight: "500",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2",
      fontWeight: "700",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfCRc4EsA.woff2",
      fontWeight: "900",
    },
  ],
});

const color = "#E4E4E4";
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: "2cm 1cm",
  },
  title: {
    position: "absolute",
    fontSize: 12,
    top: 15,
    left: 0,
    right: 0,
    textAlign: "center",
    // color,

    // width: "100%",
    // textAlign: "center",
    // fontWeight: "extrabold",
    // fontSize: "0.5cm",
    // backgroundColor: color,
    // marginBottom: "0.8cm",
  },
  subTitle: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#ccc",
    marginTop: "0.5cm",
    marginBottom: "0.3cm",
    padding: "0.2cm",
    fontSize: "0.48cm",
    fontWeight: "900",
  },

  patientDetails: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    fontSize: "0.4cm",
    border: "3pt solid #ddd",
    padding: "0.3cm",
    marginBottom: "0.3cm",
    fontWeight: 900,
  },
  patientDetailsLeft: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: "0.2cm",
  },
  borderContainer: {
    border: "1pt solid #ddd",
    padding: "0.3cm",
    backgroundColor: "#fff",
  },
  subTitle2: {
    textAlign: "left",
    marginTop: "0.4cm",
    marginBottom: "0.3cm",
    backgroundColor: "#eee",

    padding: "0.2cm",
    fontSize: "0.5cm",
    fontWeight: "700",
  },
  subHeadingBox: {
    fontSize: "0.5cm",
    display: "flex",
    flexDirection: "row",
  },
  subHeadingText: {
    backgroundColor: color,
    padding: "0.2cm",
  },
  queHeadingBox: {
    fontSize: "0.5cm",
    display: "flex",
    flexDirection: "row",
  },
  queHeadingText: {
    fontSize: "0.4cm",
    padding: "0.1cm",
  },
  queContainer: {
    border: "1pt solid #ddd",
    padding: "0.2cm",
    backgroundColor: "#fff",
  },
  patientTitle: {
    padding: "0.2cm",
    fontWeight: 900,
  },
  patientContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  test: {
    backgroundColor: "red",
  },
  bulletinContainer: {
    width: "95%",
    wordWrap: "break-word",
    whiteSpace: "normal",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  bulletinPoint: {
    width: "0.15cm",
    height: "0.15cm",
    backgroundColor: color,
    margin: "1.7mm",
    marginRight: "0.2cm",
  },
  normalData: {
    marginBottom: "0.1cm",
    fontSize: "0.4cm",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "heavy",
  },
  space: {
    padding: "2mm",
  },
  termsAndCond: {
    fontSize: 10,
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "row",
    fontSize: 12,
  },
  signatureChild: {
    width: "50%",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    // color: "grey",
  },
  dateFooter: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    right: 0,
    marginRight: "2cm",
  },
  patientFooter: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    marginLeft: "2cm",
  },

  table: {
    marginBottom: "0.3cm",
    border: "1pt solid #000",
    borderLeft: "none",
    borderBottom: "none",
  },
  row: {
    flexDirection: "row",
  },
  cellHeader: {
    flex: 1,
    fontSize: "0.3cm",
    fontWeight: "bold",
    padding: "0.3cm",
    border: "1pt solid #000",
    borderRight: "none",
    borderTop: "none",
    textAlign: "center",
  },
  cell: {
    textAlign: "center",
    fontSize: "0.3cm",
    padding: "0.3cm",
    flex: 1,
    border: "1pt solid #000",
    borderRight: "none",
    borderTop: "none",
    borderCollapse: "collapse",
  },
  cellTotal: {
    flex: 1,

    textAlign: "right",
    fontSize: 12,
    padding: 8,
    fontWeight: "bold",
    borderWidth: 1,
    borderColor: "#000",
    borderRightWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f0f0f0",
  },
});

const convertDateTostring = (date) => {
  // console.log(date, typeof date, date.replace("T", " ").replace(".000Z", ""));

  const res = date.replace("T", " ").replace(".000Z", "");

  return res;
};
const formatAMPM = (date) => {
  if (date === undefined) return "";
  if (typeof date === "string") {
    date = new Date(date);
  }
  var dd = date.getDate(date);
  var mm = date.getMonth(date) + 1;

  const yy = date.getFullYear(date);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  dd = dd < 10 ? "0" + dd : dd;
  mm = mm < 10 ? "0" + mm : mm;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = `${mm}/${dd}/${yy} ${hours}:${minutes} ${ampm}`;
  return strTime;
};

const Bulletin = ({ children }) => {
  return (
    <View style={styles.bulletinContainer}>
      <View style={styles.bulletinPoint}></View>
      <Text style={styles.normalData}>{children}</Text>
    </View>
  );
};

const ContentBox = ({ heading, normalData, BulletinData }) => {
  return (
    <View wrap={false}>
      <View style={styles.subHeadingBox}>
        <Text style={[styles.subHeadingText]}>{heading}</Text>
      </View>
      <View style={styles.borderContainer}>
        {normalData?.length &&
          normalData.map((el) => <Text style={styles.normalData}>{el}</Text>)}
        {BulletinData?.length &&
          BulletinData.map((el) => (
            <Bulletin>
              <>{el}</>
            </Bulletin>
          ))}

        {/* <Bulletin></Bulletin>
        <Bulletin />
        <Bulletin /> */}
      </View>
    </View>
  );
};
const QuestionerieBox = ({
  heading,
  normalData,
  BulletinData,
  BulletinData2,
  BulletinData2head,
}) => {
  return (
    <View wrap={false}>
      <View style={styles.queContainer}>
        <Text style={[styles.queHeadingText]}>{heading}</Text>
        {normalData?.length &&
          normalData.map((el) => <Text style={styles.normalData}>{el}</Text>)}
        {BulletinData?.length &&
          BulletinData.map((el) => (
            <Bulletin>
              <>{el}</>
            </Bulletin>
          ))}
        {BulletinData2 ? (
          <>
            <Text style={[styles.queHeadingText]}>{BulletinData2head}</Text>
            <Bulletin>
              <>{BulletinData2}</>
            </Bulletin>
          </>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

const Table = ({ heading, data, total }) => {
  return (
    <View style={styles.table}>
      {/* Render the heading row */}
      <View style={styles.row}>
        {heading?.map((head, index) => (
          <Text key={index} style={styles.cellHeader}>
            {head}
          </Text>
        ))}
      </View>

      {/* Render the data rows */}
      {data?.map((rowData, rowIndex) => (
        <View key={rowIndex} style={styles.row}>
          {rowData.map((cellData, cellIndex) => (
            <Text key={cellIndex} style={styles.cell}>
              {cellData}
            </Text>
          ))}
        </View>
      ))}
      {total && (
        <View style={styles.row}>
          <Text style={styles.cellTotal}>Total = {total}</Text>
        </View>
      )}
    </View>
  );
};

const Space = () => {
  return <View style={styles.space}></View>;
};
const doc = ({ data, notesData, billingHistory, date }) => {
  // <PDFViewer>
  const padEnd = 20;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber}`}
          fixed
        />
        <Text
          style={[styles.title]}
          render={() =>
            `CCM Service Summary for ${formatAMPM(
              new Date(date ?? new Date())
            )}`
          }
          fixed
        />
        <Text
          style={[styles.dateFooter]}
          render={() => `Print date : ${formatAMPM(new Date())}`}
          fixed
        />
        <Text
          style={[styles.patientFooter]}
          render={() => `${data.Name}`}
          fixed
        />
        <View style={styles.patientDetails}>
          <View style={styles.patientDetailsLeft}>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Patient                      "} `}</Text>
              </View>
              : {data?.Name ?? ""}
            </Text>

            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Care Plan Physician "} `}</Text>
              </View>
              : {data?.ProviderId?.ProviderName ?? " -"}
            </Text>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Date of Birth             "} `}</Text>
              </View>
              : {data?.["Date Of Birth"] ?? ""}
            </Text>
          </View>
          <View style={styles.patientDetailsLeft}>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Primary Care Coordinator        "} `}</Text>
              </View>
              : {data?.PrimaryCoordinator?.ClinicianName ?? ""}
            </Text>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Primary CCM Clinician             "} `}</Text>
              </View>
              : {data?.PrimaryCoordinator?.ClinicianName ?? " -"}
            </Text>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Facility                                      "} `}</Text>
              </View>
              : {data?.FacilityId?.FacilityName ?? " -"}
            </Text>
          </View>
        </View>
        <View style={[styles.subTitle]}>
          <Text>Service Log Details</Text>
        </View>
        <View>
          {billingHistory && (
            <Table
              heading={[
                "Service Provider",
                "Service Name",
                "Patient Number",
                "Service Data (MM-DD- YYYY)",
                "Start Time (HH:MI:SS)",
                "End Time (HH:MI:SS)",
                "Time Spent (HH:MI:SS)",
              ]}
              data={billingHistory[0]?.services?.map((service) => [
                service?.serviceProviderProfile?.FullName,
                service?.serviceName,
                data?.["Phone Number"],
                service?.startTime?.slice(0, 10),
                service?.startTime?.slice(11, 19),
                service?.endTime?.slice(11, 19),
                service?.acutalTimeSpent,
              ])}
              total={billingHistory[0]?.totalTimeSpent}
            />
          )}
        </View>

        <View>
          {data?.Diagnosis?.length > 0 && (
            <>
              <ContentBox
                heading="Diagnosis"
                BulletinData={data?.Diagnosis?.map((el) => (
                  <>
                    {el.ICD_Description}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{` - (${el.Type})`}</Text>
                  </>
                ))}
              />
              <Space />
            </>
          )}
        </View>
        <View style={[styles.subTitle]}>
          <Text>Questionnaire Response</Text>
        </View>

        {notesData?.length > 0 &&
          notesData.map((group) => (
            <>
              <View style={[styles.subTitle2]}>
                <Text>{group?.Group}</Text>
              </View>
              <View>
                {group?.subGroupList?.map((subGroup) => (
                  <>
                    <View style={[styles.subTitle2]}>
                      <Text>{subGroup?.subGroupName}</Text>
                    </View>

                    {subGroup?.quesList.map(
                      (que) =>
                        que.Response?.some((res) => res.month_yr === date) && (
                          <QuestionerieBox
                            heading={que.que}
                            BulletinData={que.Response.find(
                              (res) => res.month_yr === date
                            )?.answer?.map((el) => (
                              <Text style={{ fontWeight: "heavy" }}>{el}</Text>
                            ))}
                            BulletinData2={
                              que?.Response?.find(
                                (res) => res?.month_yr === date
                              )?.notes?.length > 0 ? (
                                <Text style={{ fontWeight: "heavy" }}>
                                  {
                                    que.Response.find(
                                      (res) => res.month_yr === date
                                    )?.notes
                                  }
                                </Text>
                              ) : (
                                false
                              )
                            }
                            BulletinData2head={"Notes"}
                          />
                        )
                    )}
                  </>
                ))}
              </View>
            </>
          ))}

        <Space />
        <Space />
        <Space />
        <Space />
        {/* <View style={styles.signatureContainer}>
          <Text style={styles.signatureChild}>Signature :</Text>
        </View> */}
        <Space />
        {/* <View style={styles.signatureContainer}>
          <Text style={styles.signatureChild}>Reviewed By :</Text>
          <Text style={styles.signatureChild}>Date :</Text>
        </View> */}
      </Page>
    </Document>
  );
};

export default doc;
